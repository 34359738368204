import React from "react";
import Card from "react-bootstrap/Card";
import { ImPointRight } from "react-icons/im";

function AboutCard() {
  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <p style={{ textAlign: "justify" }}>
            Hi Everyone, I am <span className="purple">Shrayus Desarkar </span>
            from <span className="purple"> Bengaluru, India.</span>
            <br />
            I am currently employed as a Automation Analyst at Amazon.
            <br />
            I have completed Bachelor of Technology (BTech) in Electrical Engineering at UEM Jaipur.
            <br />
            <br />
            Apart from coding, I like reading and travelling!
          </p>
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default AboutCard;
